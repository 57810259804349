/* eslint-disable react/no-unknown-property */

import { makeStyles, TextField, IconButton } from '@material-ui/core'
import { Colors } from '../../styles'
import { SearchIcon } from '../svg'
import { Wrapper, Container, ErrorText, Box } from './searchbox.styled'
import ClearIcon from '@material-ui/icons/Clear'

const Search = (props) => {
  const useStyles = makeStyles(() => ({
    noBorder: {
      border: 'none',
    },
    style:{
      height:35,
    },
    button: {
      height: '100%',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 6,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 6,
      background: Colors.backgroundColor,
    },
    reset: {
      height: 35,
      marginRight:16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'transparent',
    }
  }))
  const classes = useStyles()
  return (
    <Box width={325}>
      <Container
        id='m_component_search_txt'
      >
        <Wrapper>
          <TextField
            {...props}
            onClick={null}
            onKeyPress={props.onKeyPress}
            onKeyDown={props.onKeyDown}
            fullWidth
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            InputProps={{ classes: { notchedOutline: classes.noBorder },
              className:classes.style }}
            placeholder={props.placeholder}
            size='small'
            variant='outlined'
            onChange={props.onChangeSearch}
            value={props.search}
          />
          {
            props.reset &&
                      <div className={classes.reset} onClick={props.onClickReset} disableRipple={true}>
                        <ClearIcon />
                      </div>
          }
          <IconButton className={classes.button} onClick={props.onSearchClick} disableRipple={true}>
            <SearchIcon />
          </IconButton>
        </Wrapper>
        <ErrorText>{props.errors}</ErrorText>
      </Container>
    </Box>
  )
}
export default Search
