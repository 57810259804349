import moment from 'moment'
import getConfig from 'next/config'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useCallback } from 'react'
import { GoogleMap, useLoadScript } from '@react-google-maps/api'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { Images } from '../../assets'
import { Alerts, Loading } from '../../components'
import Marker from './maps.marker.component'
import SearchBar from './maps.searchbar.component'
import { UPDATE_VIEW_MAP } from '../../redux/actions'
import AISNavigationalStatus from './maps.navigational-status.json'
import {
  Bullets,
  DetailRow,
  DetailTitle,
  DetailRowFlexItem,
  DetailInformation,
  VesselDetailWrapper,
  DetailTitleContainer,
  VesselDetailContainer,
} from './maps.styled'

const Maps = ({
  listPort,
  location,
  listVessel,
  isRecenter,
  isFullScreen,
  userLocation,
  setIsRecenter,
  isSatelliteView,
  vesselDropdown,
}) => {
  const { publicRuntimeConfig } = getConfig()
  const viewPort = useSelector((state) => state.mapsReducer)
  const dispatch = useDispatch()
  const [map, setMap] = useState(null)
  const [vesselDetail, setVesselDetail] = useState({})
  const [showVesselDetail, setShowVesselDetail] = useState(false)
  const [markerVesselSearch, setMarkerVesselSearch] = useState(null)
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.GMAPS_API_KEY || publicRuntimeConfig.GMAPS_API_KEY,
  })
  const [showAlert, setAlert] = useState({ visible: false, msg: '', success: false })

  useEffect(() => {
    dispatch({
      type: UPDATE_VIEW_MAP,
      payload: {
        ...viewPort,
        zoom: 5,
        latitude: location.Latitude,
        longitude: location.Longitude,
        width: '100vw',
      },
    })
  }, [location])

  useEffect(() => {
    dispatch({
      type: UPDATE_VIEW_MAP,
      payload: {
        ...viewPort,
        width: '100vw',
        height: isFullScreen ? '100vh' : 'calc(88.72vh)',
      },
    })
  }, [isFullScreen])

  useEffect(() => {
    if (map) {
      map.panTo(userLocation)
      setIsRecenter(false)
    }
  }, [isRecenter])

  const onLoad = useCallback(function callback(initMap) {
    setMap(initMap)
  }, [])

  const onUnmount = useCallback(function callback() {
    setMap(null)
  }, [])

  const renderMap = () => {
    const _handleMarkerClick = (dataDetail) => {
      setVesselDetail(dataDetail)
      setShowVesselDetail(true)
    }

    const _handleCloseDetail = () => {
      setShowVesselDetail(false)
      setVesselDetail({})
      setMarkerVesselSearch(null)
    }

    const _formatTime = (value) => {
      if (!value) {
        return ''
      }
      const diff = moment().diff(moment(value), 'year')
      if (diff > 1000) {
        return ''
      } else {
        return moment(value).format('MMM DD, YYYY HH:mm')
      }
    }

    const _closeAlert = () => {
      setAlert({ msg: '', success: false, visible: false })
    }

    return (
      <GoogleMap
        center={location}
        options={{
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          zoomControl: false,
          fullscreenControl: false,
        }}
        mapContainerStyle={{ width: viewPort.width, height: viewPort.height }}
        mapTypeId={isSatelliteView ? 'satellite' : 'roadmap'}
        zoom={viewPort.zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onZoomChanged={() => {
          if (map) {
            dispatch({
              type: UPDATE_VIEW_MAP,
              payload: {
                zoom: map.zoom,
              },
            })
          }
        }}
        onCenterChanged={() => {
          if (map) {
            dispatch({
              type: UPDATE_VIEW_MAP,
              payload: {
                latitude: map.getCenter().lat(),
                longitude: map.getCenter().lng(),
              },
            })
          }
        }}
      >
        {markerVesselSearch && (
          <Marker
            id='dashboard-map-vessel-search-marker'
            disableTooltip
            position={{ lat: markerVesselSearch.lat, lng: markerVesselSearch.lng }}
            icon={(() => {
              if (markerVesselSearch.color === 'green') {
                return Images.vesselIconGreenPulse
              } else if (markerVesselSearch.color === 'red') {
                return Images.vesselIconRedPulse
              } else if (markerVesselSearch.color === 'black') {
                return Images.vesselIconBlackPulse
              } else {
                return Images.vesselIconPurple
              }
            })()}
          />
        )}
        {userLocation.error === '' && (
          <Marker
            id='dashboard-map-user-location-marker'
            disableTooltip
            position={{ lat: userLocation.lat, lng: userLocation.lng }}
            icon={Images.currentLocationIcon}
          />
        )}
        {listVessel &&
          listVessel.length &&
          listVessel.map((vessel) => (
            <Marker
              key={vessel.Id}
              id={`dashboard-map-vessel-marker-${vessel.Vessel_Code}`}
              position={{ lat: parseFloat(vessel.Latitude), lng: parseFloat(vessel.Longitude) }}
              title={vessel.Vessel_Name}
              subtitle={vessel.Vessel_Type_Text}
              srNo={vessel.Sr_No}
              onClick={() => _handleMarkerClick(vessel)}
              colorIndicator={vessel.Color_Indicator}
              icon={
                vessel.Color_Indicator === 'green'
                  ? Images.vesselIconGreen
                  : vessel.Color_Indicator === 'red'
                    ? Images.vesselIconRed
                    : Images.vesselIconBlack
              }
            />
          ))}
        {listPort &&
          listPort.length &&
          listPort
            .filter((port) => port.Latitude && port.Longitude)
            .map((port) => (
              <Marker
                key={port.Id}
                id={`dashboard-map-port-marker-${port.Id}`}
                position={{ lat: port.Latitude, lng: port.Longitude }}
                title={`PORT ${port.Location_Text}`}
                subtitle={`Interport - ${port.Location_Text}`}
                icon={Images.portIcon}
                colorIndicator='orange'
              />
            ))}
        <VesselDetailWrapper>
          <SearchBar
            map={map}
            setAlert={setAlert}
            id='dashboard-map-searchbar'
            vesselDropdown={vesselDropdown}
            setVesselDetail={setVesselDetail}
            setShowVesselDetail={setShowVesselDetail}
            setMarkerVesselSearch={setMarkerVesselSearch}
          />
          {showVesselDetail && (
            <VesselDetailContainer>
              <DetailTitleContainer>
                <DetailTitle>
                  <div style={{ fontSize: '1.74vh', fontWeight: 'bold' }}>
                    {vesselDetail.Vessel_Name ? vesselDetail.Vessel_Name.toUpperCase() : ''}
                  </div>
                  <div style={{ fontSize: '1.33vh', fontWeight: 'bold' }}>
                    {vesselDetail.Vessel_Type_Text}
                  </div>
                </DetailTitle>
                <IconButton
                  id='dashboard-map-vessel-detail-close-btn'
                  onClick={_handleCloseDetail}
                  style={{ marginRight: 7 }}
                >
                  <CloseIcon style={{ color: 'white' }} />
                </IconButton>
              </DetailTitleContainer>
              <img
                style={{
                  maxHeight: '15vh',
                  objectFit: 'cover',
                }}
                width='100%'
                src={
                  vesselDetail && vesselDetail.Image_Filebase64
                    ? vesselDetail.Image_Filebase64
                    : Images.vesselImage
                }
                alt='Vessel'
              />
              <DetailInformation>INFORMATION</DetailInformation>
              <DetailRow>
                <DetailRowFlexItem>
                  <Bullets />
                  <span>Service Request No</span>
                </DetailRowFlexItem>
                <DetailRowFlexItem bold>{vesselDetail.Sr_No}</DetailRowFlexItem>
              </DetailRow>
              <DetailRow>
                <DetailRowFlexItem>
                  <Bullets />
                  <span>Vessel Category</span>
                </DetailRowFlexItem>
                <DetailRowFlexItem bold>{vesselDetail.Vessel_Category_Text}</DetailRowFlexItem>
              </DetailRow>
              <DetailRow>
                <DetailRowFlexItem>
                  <Bullets />
                  <span>IMO Number</span>
                </DetailRowFlexItem>
                <DetailRowFlexItem bold>{vesselDetail.Imo_No}</DetailRowFlexItem>
              </DetailRow>
              <DetailRow>
                <DetailRowFlexItem>
                  <Bullets />
                  <span>Latitude</span>
                </DetailRowFlexItem>
                <DetailRowFlexItem bold>{vesselDetail.Latitude}</DetailRowFlexItem>
              </DetailRow>
              <DetailRow>
                <DetailRowFlexItem>
                  <Bullets />
                  <span>Longitude</span>
                </DetailRowFlexItem>
                <DetailRowFlexItem bold>{vesselDetail.Longitude}</DetailRowFlexItem>
              </DetailRow>
              <DetailRow>
                <DetailRowFlexItem>
                  <Bullets />
                  <span>ETA</span>
                </DetailRowFlexItem>
                <DetailRowFlexItem bold>{_formatTime(vesselDetail.Eta)}</DetailRowFlexItem>
              </DetailRow>
              <DetailRow>
                <DetailRowFlexItem>
                  <Bullets />
                  <span>ETD</span>
                </DetailRowFlexItem>
                <DetailRowFlexItem bold>{_formatTime(vesselDetail.Etd)}</DetailRowFlexItem>
              </DetailRow>
              <DetailRow>
                <DetailRowFlexItem>
                  <Bullets />
                  <span>Status</span>
                </DetailRowFlexItem>
                <DetailRowFlexItem bold>
                  {vesselDetail.Status ? AISNavigationalStatus[vesselDetail.Status] : ''}
                </DetailRowFlexItem>
              </DetailRow>
              <DetailRow>
                <DetailRowFlexItem>
                  <Bullets />
                  <span>Speed</span>
                </DetailRowFlexItem>
                <DetailRowFlexItem bold>{vesselDetail.Speed}</DetailRowFlexItem>
              </DetailRow>
              <DetailRow>
                <DetailRowFlexItem>
                  <Bullets />
                  <span>Measure Distance</span>
                </DetailRowFlexItem>
                <DetailRowFlexItem bold>{vesselDetail.Distance_To_Go}</DetailRowFlexItem>
              </DetailRow>
              <DetailRow>
                <DetailRowFlexItem>
                  <Bullets />
                  <span>Position Received</span>
                </DetailRowFlexItem>
                <DetailRowFlexItem bold>
                  {_formatTime(vesselDetail.Time_Received)}
                </DetailRowFlexItem>
              </DetailRow>
            </VesselDetailContainer>
          )}
        </VesselDetailWrapper>
        {showAlert.visible && (
          <Alerts
            isOnFullscreenComponent
            id='dashboard-map-alert'
            msg={showAlert.msg}
            close={() => _closeAlert()}
            open={showAlert.visible}
            success={showAlert.success}
          />
        )}
      </GoogleMap>
    )
  }
  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loading />
    </div>
  )
}

Maps.propTypes = {
  location: PropTypes.object,
  markers: PropTypes.array,
  setIsRecenter: PropTypes.func,
  isRecenter: PropTypes.bool,
  isSatelliteView: PropTypes.bool,
  isFullScreen: PropTypes.bool,
}

export default Maps
